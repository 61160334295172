import { userAxios } from '../../index'

const procedureList = async (perPage) => {
    try {
        return await userAxios.get(`inHouseInterventions/all/${perPage}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const procedureCreate = async (data) => {
    try {
        return await userAxios.post('inHouseInterventions', data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const procedureUpdate = async (id, data) => {
    try {
        return await userAxios.put(`inHouseInterventions/${id}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
} 

const procedureDelete = async (id) => {
    try {
        return await userAxios.delete(`inHouseInterventions/${id}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const procedurePagination = async (perPage, page) => {
    try {
        return await userAxios.get(`inHouseInterventions/all/${perPage}?page=${page}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const procedureFilter = async (perPage, data) => {
    try {
        return await userAxios.post(`inHouseInterventions/search/${perPage}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const procedureFilterPagination = async (perPage, page, data ) => {
    try {
        return await userAxios.post(`inHouseInterventions/search/${perPage}?page=${page}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

export default {
    procedureList,
    procedureCreate,
    procedureUpdate,
    procedureDelete,
    procedurePagination,
    procedureFilter,
    procedureFilterPagination
}