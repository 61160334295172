<template>
    <div class="card card-custom">
        <div class="card-body p-0">
            <div class="py-lg-5 px-lg-5">
                <h3>Total $ {{ $store.state.subtotalPrice }}</h3>
                <p v-if="(subtotalPrice - consultation.price) > 30">
                    This action is being monitored by the regional director!
                </p>
                <div v-if="campaign_cupon">
                    <h4>Campaign: {{ campaign_cupon.campaign.name }}</h4>
                    <p>Value:
                        {{ campaign_cupon.campaign.value }} {{ campaign_cupon.campaign.type == "percentage" ? "%" : ""
                        }}
                    </p>
                </div>
                <hr />
                <h4>Package</h4>
                <p v-if="packageView() != ''">
                    <b-badge variant="primary" class="text-white">
                        <span class="badge-wrapper">
                            {{ packageView() }}
                        </span>
                    </b-badge>
                </p>
                <h4>Laboratory</h4>
                <div v-for="(labo, index) in laboratorySelected" class="list-unstyled">
                    <p v-for="(lab, i) in labo">
                        <b-badge variant="primary" class="text-white">
                            <span class="badge-wrapper">
                                {{ lab.name }}
                            </span>
                        </b-badge>
                    </p>
                </div>
                <h4>Interventions</h4>
                <span v-for="(intervention, index) in interventionsProcedure">
                    <p v-for="(int, i) in intervention">
                        <b-badge variant="primary" class="text-white">
                            <span class="badge-wrapper">
                                {{ int.name }}
                            </span>
                        </b-badge>
                    </p>
                </span>
                <h4>Treatments</h4>
                <span v-for="(treatment, index) in treatmentView()">
                    <p>
                        <b-badge variant="primary" class="text-white">
                            <span class="badge-wrapper">
                                {{ treatment }}
                            </span>
                        </b-badge>
                    </p>
                </span>
                <h4>Diagnostic imaging</h4>
                <span v-for="(diagnostic, index) in diagnosticSelected">
                    <p v-for="(diag, i) in diagnostic">
                        <b-badge variant="primary" class="text-white">
                            <span class="badge-wrapper">
                                {{ diag.name }}
                            </span>
                        </b-badge>
                    </p>
                </span>
                <hr />
                <div class="campaign-info" v-if="membership">
                    <h4 class="info-title">Membership: {{ getName(membership) }}</h4>
                    <div class="info-details">
                        <p>Dates: {{ membership.start_date }} - {{ membership.end_date }} </p>
                        <p>Id: {{ membership.id }}</p>
                        <p>Consultations:</p>
                        <ul class="list-unstyled my-2 facility">
                            <li v-for="(consult, i) in getArray(membership, 'consultations')" :key="i">
                                <b-badge variant="primary">
                                    <span class="text-white badge-wrapper">
                                        Count: {{ consult.count }} -
                                        Activation Date: {{ consult.activation_date }} -
                                        Count Used: {{ consult.count_used }} -
                                        Count Available: {{ consult.count_available }}
                                    </span>
                                </b-badge>
                            </li>
                        </ul>
                        <p>Diagnostic Imagings:</p>
                        <ul class="list-unstyled my-2 facility">
                            <li v-for="(diagnostic, i) in getArray(membership, 'diagnostic_imagings')" :key="i">
                                <b-badge class="badge-wrapper" variant="primary">
                                    <span class="text-white">
                                        {{ diagnostic.name }} -
                                        {{ diagnostic.activation_date }} -
                                        Count Used: {{ diagnostic.count_used }} -
                                        Count Available: {{ diagnostic.count_available }}
                                    </span>
                                </b-badge>
                            </li>
                        </ul>
                        <p>laboratories:</p>
                        <ul class="list-unstyled my-2 facility">
                            <li v-for="(laboratories, i) in getArray(membership, 'laboratories')" :key="i">
                                <b-badge class="badge-wrapper" variant="primary">
                                    <span class="text-white">
                                        {{ laboratories.name }} -
                                        {{ laboratories.activation_date }} -
                                        Count Used: {{ laboratories.count_used }} -
                                        Count Available: {{ laboratories.count_available }}
                                    </span>
                                </b-badge>
                            </li>
                        </ul>
                        <p>laboratories_pack:</p>
                        <ul class="list-unstyled my-2 facility">
                            <li v-for="(laboratories_pack, i) in getArray(membership, 'laboratories_pack')" :key="i">
                                <b-badge class="badge-wrapper" variant="primary">
                                    <span class="text-white">
                                        {{ laboratories_pack.name }} -
                                        {{ laboratories_pack.activation_date }} -
                                        Count Used: {{ laboratories_pack.count_used }} -
                                        Count Available: {{ laboratories_pack.count_available }}
                                    </span>
                                </b-badge>
                            </li>
                        </ul>
                        <p>procedures:</p>
                        <ul class="list-unstyled my-2 facility">
                            <li v-for="(procedures, i) in getArray(membership, 'procedures')" :key="i">
                                <b-badge class="badge-wrapper" variant="primary">
                                    <span class="text-white">
                                        {{ procedures.name }} -
                                        {{ procedures.activation_date }} -
                                        Count Used: {{ procedures.count_used }} -
                                        Count Available: {{ procedures.count_available }}
                                    </span>
                                </b-badge>
                            </li>
                        </ul>
                        <p>tests:</p>
                        <ul class="list-unstyled my-2 facility">
                            <li v-for="(tests, i) in getArray(membership, 'tests')" :key="i">
                                <b-badge class="badge-wrapper" variant="primary">
                                    <span class="text-white">
                                        {{ tests.name }} -
                                        {{ tests.activation_date }} -
                                        Count Used: {{ tests.count_used }} -
                                        Count Available: {{ tests.count_available }}
                                    </span>
                                </b-badge>
                            </li>
                        </ul>
                        <p>treatments:</p>
                        <ul class="list-unstyled my-2 facility">
                            <li v-for="(treatments, i) in getArray(membership, 'treatments')" :key="i">
                                <b-badge class="badge-wrapper" variant="primary">
                                    <span class="text-white">
                                        {{ treatments.name }} -
                                        {{ treatments.activation_date }} -
                                        Count Used: {{ treatments.count_used }} -
                                        Count Available: {{ treatments.count_available }}
                                    </span>
                                </b-badge>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import { ValidationProvider } from "vee-validate";
// import { BRow, BCol, BFormGroup, BInputGroup, BFormInput } from "bootstrap-vue";
export default {
    components: {
        // ValidationProvider,
        // BRow,
        // BCol,
        // BFormGroup,
        // BInputGroup,
        // BFormInput,
    },
    props: {
        totalPriceDiagnosticImaging: {
            type: Number,
            default: 0,
        },
        totalPriceLaboratory: {
            type: Number,
            default: 0,
        },
        totalPriceInterventions: {
            type: Number,
            default: 0,
        },
        totalPriceTreatment: {
            type: Number,
            default: 0,
        },
        totalPricePackage: {
            type: Number,
            default: 0,
        },
        consultation: {
            type: Object,
            required: true,
        },
        laboratorySelected: {
            type: Object,
            required: true,
        },
        interventionsProcedure: {
            type: Object,
            required: true,
        },
        treatmentProcedure: {
            type: Array,
            required: true,
        },
        treatmentOptions: {
            type: Array,
            required: true,
        },
        diagnosticSelected: {
            type: Object,
            required: true,
        },
        membership: {
            type: Object || null,
            required: false,
        },
        package: {
            type: Object,
            required: true,
        },
        campaign_cupon: {
            type: Object,
            required: false,
        },
    },
    computed: {
        subtotalPrice() {
            return (
                this.totalPriceDiagnosticImaging +
                this.totalPriceLaboratory +
                this.totalPriceInterventions +
                this.totalPriceTreatment +
                this.totalPricePackage +
                this.consultation.price
            );
        },
    },
    methods: {
        getName(membership) {
            if (membership && membership.memberships_parameters) return membership.memberships_parameters.name
            return ""
        },
        getArray(membership, property) {
            if (membership && membership.memberships_parameters) return membership.memberships_parameters[property]
            return []
        },
        packageView() {
            if (!this.package.data) return ''
            let pkg = this.package.all.find(pack => pack.id == this.package.data)
            return pkg.name
        },
        treatmentView() {
            let treatment = this.treatmentProcedure.filter(treat => treat.cant != null), treatments = []
            treatment.forEach(treat => {
                this.treatmentOptions.map(treats => {
                    if (treats.id == treat.id) {
                        treatments.push(treats.name)
                    }
                })
            });
            return treatments
        }
    }
}
</script>

<style>
.campaign-info {
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    border-radius: 8px;
    width: 100%;
}

.badge-wrapper {
    overflow: auto;
    white-space: pre-wrap;
}

.divider {
    background: black;
}
</style>
