<template>
    <div>
        <!-- <DiagnosesAdd
      :is-add-new-diagnoses-sidebar-active.sync="isAddNewDiagnosesSidebarActive"
      @createDiagnoses="createDiagnoses"
    /> -->
        <b-row class="mt-2">
            <b-col cols="12" class="mb-2">
                <h5 class="mb-0">Assessment</h5>
                <small class="text-muted">Enter Your Medical History.</small>
            </b-col>
            <b-col md="6">
                <validation-provider #default="{ errors }" name="PMH">
                    <b-form-group label="PMH" label-for="pmh" :state="errors.length > 0 ? false : null">
                        <!-- Agregar Buscador -->
                        <treeselect id="pmhx" v-model="medicalHistory.ailments" :load-options="searchAilment" :async="true"
                            :defaultOptions="ailmentOptions.data" :normalizer="normalizer" multiple />
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                            {{ errors[0] }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>
            </b-col>
            <!-- <b-col md="6">
        <validation-provider #default="{ errors }" name="Examination">
          <b-form-group
            label="Examination"
            label-for="examination"
            :state="errors.length > 0 ? false : null"
          >
            <div>
              <b-form-radio-group
                id="examination"
                v-model="assessmentProcedure.examination"
                :options="options"
                class="demo-inline-spacing mb-1"
                value-field="value"
                text-field="text"
                disabled-field="disabled"
              />
            </div>
            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            v-if="assessmentProcedure.examination == 'abnormal'"
            label="Describe positive"
            label-for="textarea-positive"
          >
            <b-form-textarea
              id="textarea-positive"
              class="text-area-assesment"
              v-model="assessmentProcedure.describeAbnormal"
              placeholder="Describe positive"
              rows="3"
            />
          </b-form-group>
        </validation-provider>
      </b-col> -->
            <b-col md="6">
                <b-form-group label="Diagnoses" label-for="textarea-diagnoses">
                    <b-form-textarea id="textarea-diagnoses" class="text-area-assesment"
                        v-model="assessmentProcedure.describeDiagnoses" placeholder="Describe Diagnoses" rows="3" />
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <validation-provider #default="{ errors }" name="Frequent Diagnoses">
                    <b-form-group label-for="frequent diagnoses" :state="errors.length > 0 ? false : null">
                        <div class="d-flex justify-content-between">
                            <label for="">Frequent diagnoses</label>
                            <!-- <b-button
                size="sm"
                variant="success"
                class="btn-icon mb-1"
                @click="isAddNewDiagnosesSidebarActive = true"
              >
                <i class="wizard-icon fas fa-plus-circle"></i>
              </b-button> -->
                        </div>
                        <treeselect id="diagnoses" v-model="assessmentProcedure.diagnosis" :async="true"
                            :load-options="searchFacility" :defaultOptions="true" :normalizer="normalizer" multiple />
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                            {{ errors[0] }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormRadioGroup,
    BFormTextarea,
    BFormInvalidFeedback,
} from "bootstrap-vue";

/* import DiagnosesAdd from "@/views/admin/type-consult/diagnoses/DiagnosesAdd.vue"; */

import axiosAI from "@/core/services/api/admin/consults/ailments";
import axiosD from "@/core/services/api/admin/consults/diagnoses";

export default {
    components: {
        ValidationProvider,
        ValidationObserver,
        BRow,
        BCol,
        BButton,
        BFormGroup,
        BFormRadioGroup,
        BFormTextarea,
        BFormInvalidFeedback,
    },
    props: {
        medicalHistory: {
            type: Object,
            required: true,
        },
        assessmentProcedure: {
            type: Object,
            default: "",
        },
        ailmentOptions: {
            type: Object,
            required: true,
        },
        diagnosesOptions: {
            type: Object,
            required: true,
        },
    },
    data() {
        this.assessmentProcedure.diagnosis = this.assessmentProcedure.diagnosis.map((diagnose) => {
            return diagnose.assessmentable_id
        })
        return {
            options: [
                { text: "Normal", value: "normal", disabled: false },
                { text: "Abnormal", value: "abnormal", disabled: false },
            ],
            isAddNewDiagnosesSidebarActive: false,
            normalizer(node) {
                return {
                    id: node.id,
                    label: node.name,
                };
            },
        };
    },
    methods: {
        getAilments() {
            axiosAI.ailmentList(10).then(({ data }) => {
                this.ailmentOptions.data = data;
            });
        },
        getDiagnoses() {
            axiosD.diagnosesList(10).then(({ data }) => {
                this.diagnosesOptions.data = data;
            });
        },
        searchFacility({ action, searchQuery, callback }) {
            if (action === "ASYNC_SEARCH") {
                const datos = {
                    name: searchQuery,
                };
                axiosD.diagnosesFilter(10, datos).then(({ data }) => {
                    callback(null, data);
                });
            }
        },
        searchAilment({ action, searchQuery, callback }) {
            if (action === "ASYNC_SEARCH") {
                clearTimeout(this.setTimeoutBuscador);
                const datos = {
                    name: searchQuery,
                    status: true,
                };
                this.setTimeoutBuscador = setTimeout(() => {
                    axiosAI.ailmentFilter(10, datos).then(({ data }) => {
                        callback(null, data);
                    });
                }, 350);
            }
        },
        createDiagnoses(value) {
            if (value) {
                this.isAddNewDiagnosesSidebarActive = false;
                this.getDiagnoses();
            } else {
                this.isAddNewDiagnosesSidebarActive = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.text-area-assesment {
    height: calc(3em + 1.3rem + 2px) !important;
}
</style>
