<template>
  <b-row class="mt-2">
    <b-col cols="6" class="mb-2">
      <h5 class="mb-0">Therapeutic Injections / Immunizations</h5>
      <small class="text-muted">Enter the corresponding treatments</small>
    </b-col>
    <b-col cols="6">
      <h3 class="d-flex justify-content-end">$ {{ totalPriceTreatment }}</h3>
      <h3 class="d-flex justify-content-end">Total $ {{ subtotalPrice }}</h3>
    </b-col>
    <b-col
      md="6"
      v-for="(item, id) in treatmentOptions"
      :key="item.id"
    >
      <b-form-group :label="item.name">
        <validation-provider #default="{ errors }" :name="item.name">
          <!-- <v-select
            v-model="treatmentProcedure[id].cant"
            :options="treatmentProcedure[id].options"
            :selectable="(option) => !option.promotion"
            :clearable="!item.promotion"
            :reduce="(value) => value.value"
            label="value"
          /> -->
          <treeselect
            id="mySelect"
            v-model="treatmentProcedure[id].cant"
            :options="treatmentProcedure[id].options"
            :normalizer="normalizer"
          >
          </treeselect>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import { BRow, BCol, BFormGroup } from "bootstrap-vue";

export default {
  components: {
    ValidationProvider,
    BRow,
    BCol,
    BFormGroup
  },
  props: {
    treatmentProcedure: {
      type: Array,
      required: true,
    },
    treatmentOptions: {
      type: Array,
      required: true,
    },
    totalPriceLaboratory: {
      type: Number,
      default: 0,
    },
    totalPriceDiagnosticImaging: {
        type: Number,
        default: 0,
    },
    totalPriceInterventions: {
        type: Number,
        default: 0,
    },
    totalPriceTreatment: {
        type: Number,
        default: 0,
    },
  },
  mounted() {
    this.getOptions();
  },
  data() {
    return {
      cantOptions: [
        { value: 1 },
        { value: 2 },
        { value: 3 },
        { value: 4 },
        { value: 5 },
        { value: 6 },
        { value: 7 },
        { value: 8 },
        { value: 9 },
        { value: 10 },
      ],
      normalizer(node) {
        return {
          id: node.value,
          label: node.value,
        };
      },
    };
  },
  computed: {
    subtotalPrice() {
        return (
            this.totalPriceDiagnosticImaging +
            this.totalPriceLaboratory +
            this.totalPriceInterventions +
            this.totalPriceTreatment 
        );
    },
  },
  methods: {
    getOptions() {
      this.treatmentOptions.forEach((element, index) => {
        if (element.promotion) {
          const optionsTreatments = [];
          const lengthPromotion = this.treatmentProcedure[index].cant;
          for (let i = 1; i <= 10; i++) {
            if (i < lengthPromotion) {
              optionsTreatments.push({
                value: i,
                promotion: true,
              });
            } else {
              optionsTreatments.push({
                value: i,
                promotion: false,
              });
            }
          }
          this.treatmentProcedure[index].options = optionsTreatments;
        } else {
          const kenalog = this.treatmentProcedure.findIndex((treatment)=> treatment.id == 7)
          this.treatmentProcedure[kenalog].nameTreatment = "kenalog";
          this.treatmentProcedure[index].options = this.cantOptions;
        }
      });
    },
  },
};
</script>

<style>
</style>