<template>
  <b-modal id="cost" v-model="conditionCost" hide-footer hide-header centered content-class="transparent" size="md"
    modal-class="modal-danger text-center" title="Danger Modal" ref="sexoModal" @hidden="onHide">
    <b-card align="center" class="position-static" body-border-variant="primary">
      <blockquote class="blockquote mb-0">
        <div v-if="discounts.consultations.total">
          <h5>Consultations: {{ discounts.consultations.total }}</h5>
        </div>
        <div v-if="discounts.diagnostic_imagings.total">
          <h5>Diagnostic imagings - total: {{ discounts.diagnostic_imagings.total }}</h5>
          <div v-for="(labo, index) in discounts.diagnostic_imagings.discounts" class="list-unstyled" :key="index">
              <b-badge variant="primary" class="text-white">
                {{ labo.name }} - {{ labo.price }}
              </b-badge>
          </div>
        </div>
        <div v-if="discounts.laboratories.total">
          <h5>Laboratories - total: {{ discounts.laboratories.total }}</h5>
          <div v-for="(labo, index) in discounts.laboratories.discounts" class="list-unstyled" :key="index">
              <b-badge variant="primary" class="text-white">
                {{ labo.name }} - {{ labo.price }}
              </b-badge>
          </div>
        </div>
        <div v-if="discounts.laboratories_pack.total">
          <h5>Laboratories pack - total: {{ discounts.laboratories_pack.total }}</h5>
          <div v-for="(labo, index) in discounts.laboratories_pack.discounts" class="list-unstyled" :key="index">
              <b-badge variant="primary" class="text-white">
                {{ labo.name }} - {{ labo.price }}
              </b-badge>
          </div>
        </div>
        <div v-if="discounts.procedures.total">
          <h5>Procedures - total: {{ discounts.procedures.total }}</h5>
          <div v-for="(labo, index) in discounts.procedures.discounts" class="list-unstyled" :key="index">
              <b-badge variant="primary" class="text-white">
                {{ labo.name }} - {{ labo.price }}
              </b-badge>
          </div>
        </div>
        <div v-if="discounts.tests.total">
          <h5>Tests - total: {{ discounts.tests.total }}</h5>
          <div v-for="(labo, index) in discounts.tests.discounts" class="list-unstyled" :key="index">
              <b-badge variant="primary" class="text-white">
                {{ labo.name }} - {{ labo.price }}
              </b-badge>
          </div>
        </div>
        <div v-if="discounts.treatments.total">
          <h5>Treatments - total: {{ discounts.treatments.total }}</h5>
          <div v-for="(labo, index) in discounts.treatments.discounts" class="list-unstyled" :key="index">
              <b-badge variant="primary" class="text-white">
                {{ labo.name }} - {{ labo.price }}
              </b-badge>
          </div>
        </div>
        <div v-if="discounts.discount_membership.total">
          <h5>Discount membership - total: {{ discounts.discount_membership.total }}</h5>
        </div>
      </blockquote>
      <b-button class="mt-3" variant="primary" @click="hideModal()">OK</b-button>
    </b-card>
  </b-modal>
</template>

<script>
import { BModal, BCard, BButton } from "bootstrap-vue";
export default {
  components: {
    BModal,
    BCard,
    BButton,
  },
  props: {
    discounts: {
      type: Object,
      default: () => { },
      required: true,
    },
  },
  data() {
    return {
      conditionCost: true,
    };
  },
  methods: {
    onHide() {
      this.$emit("backdropEvent");
    },
    hideModal() {
      const modalAttributes = this.$refs.sexoModal;
      //modalAttributes.isHidden = true
      modalAttributes.isClosing = true
      modalAttributes.isVisible = false
    }
  }
};
</script>

<style lang="scss" scoped>
.transparent {
  box-shadow: none !important;
  background-color: transparent !important;
}
</style>
