import { userAxios } from '../../index'

const getPackages = async (page) => {
    try {
        return await userAxios.get(`packages/all/${page}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const getPackage = async (id) => {
    try {
        return await userAxios.get(`packages/${id}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const getList = async () => {
    try {
        return await userAxios.get(`packages/get-lists`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const createPackage = async (data) => {
    try {
        return await userAxios.post('packages', data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const updatePackage = async (id, data) => {
    try {
        return await userAxios.put(`packages/${id}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const deletePackage = async (id) => {
    try {
        return await userAxios.delete(`packages/${id}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const packagePagination = async (perPage, page) => {
    try {
        return await userAxios.get(`packages/all/${perPage}?page=${page}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const packageFilter = async (perPage, data) => {
    try {
        return await userAxios.post(`packages/search/${perPage}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const packageFilterPagination = async (perPage, page, data ) => {
    try {
        return await userAxios.post(`sonogramsCategory/search/${perPage}?page=${page}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

export default {
    getPackages,
    getPackage,
    getList,
    createPackage,
    updatePackage,
    deletePackage,
    packagePagination,
    packageFilter,
    packageFilterPagination
}
