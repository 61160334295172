export const getTotal = ({ campaignType, total, campaignValue }) => {
  return campaignType == "value"
    ? total - campaignValue
    : Math.ceil(total - total * `0.${campaignValue}`);
};

export const getStatus = (status) => {
  const allStatus = {
    0: "Active",
    1: "Solicited",
    2: "Redeemed",
  };

  return allStatus.hasOwnProperty(status) ? allStatus[status] : allStatus[0];
};
