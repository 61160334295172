import { userAxios } from '../../index'

const diagnosticImageCategoryList = async (perPage) => {
    try {
        return await userAxios.get(`sonogramsCategory/all/${perPage}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const diagnosticImageCategoryCreate = async (data) => {
    try {
        return await userAxios.post('sonogramsCategory', data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const diagnosticImageCategoryUpdate = async (id, data) => {
    try {
        return await userAxios.put(`sonogramsCategory/${id}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const diagnosticImageCategoryDelete = async (id) => {
    try {
        return await userAxios.delete(`sonogramsCategory/${id}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const diagnosticImageCategoryPagination = async (perPage, page) => {
    try {
        return await userAxios.get(`sonogramsCategory/all/${perPage}?page=${page}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const diagnosticImageCategoryFilter = async (perPage, data) => {
    try {
        return await userAxios.post(`sonogramsCategory/search/${perPage}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const diagnosticImageCategoryFilterPagination = async (perPage, page, data ) => {
    try {
        return await userAxios.post(`sonogramsCategory/search/${perPage}?page=${page}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

export default {
    diagnosticImageCategoryList,
    diagnosticImageCategoryCreate,
    diagnosticImageCategoryUpdate,
    diagnosticImageCategoryDelete,
    diagnosticImageCategoryPagination,
    diagnosticImageCategoryFilter,
    diagnosticImageCategoryFilterPagination
}