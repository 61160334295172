<template>
  <b-modal
    id="cost"
    v-model="conditionCost"
    hide-footer
    hide-header
    centered
    content-class="transparent"
    size="md"
    modal-class="modal-danger text-center"
    title="Danger Modal"
    ref="CostModal"
    @hidden="onHide"
  >
    <b-card
      align="center"
      class="position-static"
      body-border-variant="primary"
    >
      <blockquote class="blockquote mb-0">
        <p>This action is being monitored by the regional director.</p>
      </blockquote>
      <b-button variant="primary" @click="hideModal()">OK</b-button>
    </b-card>
  </b-modal>
</template>

<script>
import { BModal, BCard, BButton } from "bootstrap-vue";
export default {
  components: {
    BModal,
    BCard,
    BButton,
  },
  data() {
    return {
      conditionCost: true,
    };
  },
  methods:{
    onHide(){
        this.$emit("backdropEvent");
    },
    hideModal(){
        const modalAttributes = this.$refs.CostModal;
        //modalAttributes.isHidden = true
        modalAttributes.isClosing = true
        modalAttributes.isVisible = false
    }
  }
};
</script>

<style lang="scss">
.transparent {
  box-shadow: none !important;
  background-color: transparent !important;
}
</style>
